import React, { useState } from "react";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
import RenderPanel from "../RenderPannel";
import { toast } from "react-toastify";
import { API_CONSTANT, post } from "../../services/ApiServices";
import { LICENSE_NUMBER_REGEX } from "../../Utils";
import moment from "moment/moment";

const LicenseInfo = () => {
  const [data, setData] = useState({});

  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkLicenseNumber = () => {
    return search.match(LICENSE_NUMBER_REGEX);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const fetchDetails = () => {
    if (!loading && checkLicenseNumber() && date !== "") {
      setError(false);
      setLoading(true);
      const params = {
        licenseNo: search.toUpperCase(),
        dob: moment(date).format("DD-MM-YYYY"),
      };
      post(API_CONSTANT.LICENSE, params)
        .then((res) => {
          if (res.data.details) {
            setData(res.data.details);
            toast.success("Record Found!");
          } else {
            setData({});
            toast.error("Record Not Found!");
          }
        })
        .catch(() => {
          toast.error("Server Error");
        })
        .finally(() => setLoading(false));
    } else {
      setError(true);
    }
  };

  return (
    <div className="py-10">
      <div className="my-5 relative flex flex-col justify-center gap-5 items-center">
        <h1 className="text-3xl md:text-5xl lg:text-7xl font-bold text-center">
          <span className="uppercase text-orange-600">rto</span>
          <br />
          License Information
        </h1>

        <div className="w-[300px] sm:w-[500px]">
          <div className="relative shadow-lg">
            <div className=" bg-white border-gray-200 border-2 px-16 py-2 rounded-md">
              <input
                className="w-full bg-transparent uppercase"
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search License Number"
              />
            </div>
            <div className="absolute top-[7px] left-2">
              <AiOutlineSearch size={30} />
            </div>
          </div>

          <div className="mt-2 flex flex-col md:flex-row justify-between items-center gap-5">
            <div className="relative shadow-lg">
              <div className="bg-white flex justify-center items-center gap-5 border-gray-200 border-2 px-5 py-2 rounded-md">
                <label className="whitespace-nowrap">Birth date:</label>
                <input
                  className="w-full bg-transparent"
                  type="date"
                  value={date}
                  onChange={handleDate}
                  placeholder="Search Vehicle Number"
                />
              </div>
            </div>
            <div className="rounded-md w-full text-center active:bg-gray-800 bg-gray-700 text-white">
              <button onClick={fetchDetails} className="px-5 py-2">
                {loading ? (
                  <div>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </div>
                ) : (
                  <div className="font-bold text-white">Find</div>
                )}
              </button>
            </div>
          </div>
          {error && (
            <div className="text-red-500 text-sm bg-transparent">
              Please Enter All Details Currectly.
              <span className="ml-1 text-black font-semibold">
                Licence No. MH0500000000000 and Date
              </span>
            </div>
          )}
        </div>

        <div className="">
          <a
            className="app-store-btn btn-google mt-3 ml-1 mr-1"
            target="_blank"
            href="/"
            role="button"
          >
            <span className="app-store-btn-subtitle">Get It On</span>
            <span className="app-store-btn-title">Google Play</span>
          </a>
          <a
            className="app-store-btn btn-apple mt-3 ml-1 mr-1"
            target="_blank"
            href="/"
            role="button"
          >
            <span className="app-store-btn-subtitle">Available on the</span>
            <span className="app-store-btn-title">App Store</span>
          </a>
        </div>
      </div>

      {Object.keys(data).length > 0 && (
        <div className="mt-5 mx-2 sm:mx-auto max-w-xl">
          <div className="relative bg-white p-4 border border-gray-300 rounded-md shadow-md">
            <div
              onClick={() => setData([])}
              className="absolute cursor-pointer right-5"
            >
              <AiOutlineCloseCircle size={30} />
            </div>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Licence Details
            </h2>
            <div className="grid grid-cols-1 leading-7 justify-items-center text-left">
              <div className="px-2 min-w-[350px]">
                <div className="flex justify-between items-center">
                  <strong className="font-semibold">License No:</strong>
                  <div className="max-w-[180px] text-right">
                    {data?.licenseNo}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <strong className="font-semibold">Date Of Birth:</strong>
                  <div className="max-w-[180px] text-right">{data.dob}</div>
                </div>
                <div className="flex justify-between items-center">
                  <strong className="font-semibold">Holder Name:</strong>
                  <div className="max-w-[180px] text-right">
                    {data?.holderName}
                  </div>
                </div>
                <RenderPanel
                  title={"Vehicle Model:"}
                  value={data?.currentStatus}
                />
                <RenderPanel
                  title={"Date Of Issue:"}
                  value={data?.dateOfIssue}
                />
                <RenderPanel
                  title={"Last Transaction At:"}
                  value={data.lastTransactionAt}
                />
                <RenderPanel title={"Valid From:"} value={data?.validFrom} />
                <RenderPanel title={"Valid To:"} value={data?.validTo} />
                <RenderPanel
                  title={"Vehicle Class:"}
                  value={data.vehicleClass}
                />
                <button
                  onClick={() =>
                    window.open(
                      "https://www.google.com/",
                      "_blank",
                      "noreferrer"
                    )
                  }
                  className="bg-blue-500 text-white cursor-pointer font-bold px-5 py-2 rounded-lg mt-5 w-full"
                >
                  Show More
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseInfo;
