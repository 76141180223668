import React from "react";
import { RiMotorbikeFill } from "react-icons/ri";
import { FaCarSide } from "react-icons/fa";

function About() {
  return (
    <div className="mx-5">
      <div className="container mx-auto">
        <div className="container mx-auto text-center max-w-md mt-10">
          <h1 className="font-serif text-5xl font-bold">Abot Our App</h1>
          <p className="text-[#999FAE] font-medium text-[18px] mt-5">
            Welcome to RTO Vehicle Information, your go-to source for
            comprehensive details about vehicles registered with the Regional
            Transport Offices (RTOs) across India.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 mt-10 justify-items-center container mx-auto max-w-[1200px]">
          <div className="">
            <img
              className="max-w-[451px] max-h-[711px]"
              src="/screen-one.jpg"
              alt=""
            />
          </div>
          <div className="max-w-lg mt-10">
            <div className="">
              <h1 className="font-serif text-4xl font-bold">
                Vehicle Details - Your Comprehensive Automotive Companion
              </h1>
              <p className="text-[#A3A9B6] font-normal mt-5">
                Our app is designed to make your life as a vehicle owner easier
                and more enjoyable. Whether you're keeping track of maintenance,
                planning a road trip, or exploring your next automotive
                purchase, Vehicle Details is the perfect companion. Download our
                app today and take control of your vehicle's information like
                never before!
              </p>
            </div>
            <div className="grid gap-y-5 mt-5">
              <div className="shadow p-3 ">
                <div className="grid grid-cols-3 gap-4 justify-items-start items-center">
                  <div className="p-4 w-fit col-span-1 rounded-xl bg-blue-100">
                    <RiMotorbikeFill color="#4285F4" size={30} />
                  </div>
                  <div className="col-span-2">
                    <h1 className="font-medium text-lg">Vehicle Profile</h1>
                  </div>
                </div>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  Create a detailed profile for each of your vehicles.
                </p>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  Add essential information such as make, model, year, VIN, and
                  registration details.
                </p>
              </div>
              <div className="shadow p-3 ">
                <div className="grid grid-cols-3 gap-4 justify-items-start items-center">
                  <div className="p-4 w-fit col-span-1 rounded-xl bg-blue-100">
                    <FaCarSide color="#4285F4" size={30} />
                  </div>
                  <div className="col-span-2">
                    <h1 className="font-medium text-lg">Maintenance Tracker</h1>
                  </div>
                </div>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  Keep track of your vehicle's maintenance history.
                </p>
              </div>
              {/* <div className='shadow p-3 '>
                            <div className='grid grid-cols-3 gap-4 justify-items-start items-center'>
                                <div className='p-4 w-fit col-span-1 rounded-xl bg-blue-100'>
                                    <FaBusSimple color='#4285F4' size={30} />
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='font-medium text-lg'>Bus Details</h1>
                                </div>
                            </div>
                            <p className='text-[#A3A9B6] font-normal mt-3'>
                                1 Lorem ipsum dolor sit amet.
                            </p>
                        </div> */}
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 mt-10 justify-items-center container mx-auto max-w-[1200px]">
          <div className="max-w-lg mt-10">
            <div className="">
              <h1 className="font-serif text-4xl font-bold">
                Challan Tracker - Stay Informed about Vehicle Violations
              </h1>
              <p className="text-[#A3A9B6] font-normal mt-5">
                Welcome to the Challan Tracker app, your reliable companion for
                keeping track of vehicle challan details. We understand the
                importance of staying informed about traffic violations, fines,
                and penalties associated with your vehicle. Our user-friendly
                app makes it easier than ever to monitor and manage your challan
                history, ensuring you're always aware of any pending fines.
              </p>
            </div>
            <div className="grid gap-y-5 mt-5">
              <div className="shadow p-3 ">
                <div className="grid grid-cols-3 gap-4 justify-items-start items-center">
                  <div className="p-4 w-fit col-span-1 rounded-xl bg-[#D2ECD9]">
                    <RiMotorbikeFill color="#34A853" size={30} />
                  </div>
                  <div className="col-span-2">
                    <h1 className="font-medium text-lg">Challan History</h1>
                  </div>
                </div>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  Access a comprehensive list of all challans associated with
                  your vehicle.
                </p>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  Review the details of each challan, including the violation,
                  date, and fine amount.
                </p>
              </div>
              <div className="shadow p-3">
                <div className="grid grid-cols-3 gap-4 justify-items-start items-center">
                  <div className="p-4 w-fit col-span-1 rounded-xl bg-[#D2ECD9]">
                    <FaCarSide color="#34A853" size={30} />
                  </div>
                  <div className="col-span-2">
                    <h1 className="font-medium text-lg">
                      Digital Challan Copies
                    </h1>
                  </div>
                </div>
                <p className="text-[#A3A9B6] font-normal mt-3">
                  View and download digital copies of your challan documents for
                  your records.
                </p>
              </div>
              {/* <div className='shadow p-3'>
                            <div className='grid grid-cols-3 gap-4 justify-items-start items-center'>
                                <div className='p-4 w-fit col-span-1 rounded-xl bg-[#D2ECD9]'>
                                    <FaBusSimple color='#34A853' size={30} />
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='font-medium text-lg'>Bus Details</h1>
                                </div>
                            </div>
                            <p className='text-[#A3A9B6] font-normal mt-3'>
                                Easy to find the all the details of bus owner
                            </p>
                        </div> */}
            </div>
          </div>
          <div className="">
            <img
              className="max-w-[451px] max-h-[711px]"
              src="/screen-two.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
