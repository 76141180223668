import React from "react";

const RenderPanel = ({ title, value }) => {
  return (
    <div className="flex justify-between items-center">
      <strong className="font-semibold">{title}</strong>
      <div className="max-w-[180px] text-right blur-sm">{value}</div>
    </div>
  );
};

export default RenderPanel;
