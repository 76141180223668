import React, { useState } from "react";
import MainHeader from "../Common/MainHeader";
import About from "../About";
import Services from "../Services";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { API_CONSTANT, post } from "../../services/ApiServices";
import RenderPanel from "../RenderPannel";
import { VEHICLE_NUMBER_REGEX } from "../../Utils";
import { toast } from "react-toastify";

function Home() {
  const [search, setSearch] = useState("");

  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // const data = {
  //   age: null,
  //   bodyTypeDesc: "SOLWPR SOLO+PILL. RIDER",
  //   cStateName: "Gujarat",
  //   chassisNo: "ME4JC58AEGT2XXXXX",
  //   engineNo: "JC58ET42XXXXX",
  //   fOwnerName: "RASIKBHAI KABARIYA",
  //   fitnessUpto: "28-Aug-2031",
  //   fuelNorms: "Not Available",
  //   fuelType: "PETROL",
  //   insuranceFrom: "26-Aug-2016",
  //   insurancePolicyNo: "44964794",
  //   insuranceUpto: "25-Aug-2017",
  //   issuerName: "BHARTI AXA GENERAL INSURANCE CO. LTD.",
  //   last_seen_on: "2023-09-05 22:08:30",
  //   makerModel: "HONDA CARS INDIA LTD / DREAM YUGA",
  //   manufactureMonthYear: "7/2016",
  //   modelName: "DREAM YUGA",
  //   ownerMobileNo: "95104XXXXX",
  //   ownerName: "RAHULBHAI",
  //   ownership: 1,
  //   ownershipDesc: "FIRST OWNER",
  //   pucUpto: "18-Sep-2023",
  //   rcStatus: "ACTIVE",
  //   registrationAuthority: "SURAT, Gujarat",
  //   registrationDate: "29-Aug-2016",
  //   registrationNo: "GJ05NG6527",
  //   roadTaxPaidUpto: "28-Aug-2031",
  //   seatCapacity: 2,
  //   unloadWeight: 108,
  //   vehicleClass: "M-Cycle/Scooter (2WN)",
  //   vehicleColor: "BLACK",
  // };

  const checkVehicleNumber = () => {
    return search.match(VEHICLE_NUMBER_REGEX);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchDetails = () => {
    if (!loading && checkVehicleNumber()) {
      setError(false);
      setLoading(true);
      const params = {
        registrationNo: search.toUpperCase(),
        key_skip_db: true,
        extra: false,
      };
      post(API_CONSTANT.VEHICLE, params)
        .then((res) => {
          if (res.data.details) {
            setData(res.data.details);
            toast.success("Record Found!");
          } else {
            setData({});
            toast.error("Record Not Found!");
          }
        })
        .catch((err) => {
          toast.error("Internal Server Error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="min-h-[100vh] px-2 sm:px-10 w-full mt-6 md:mt-10 lg:mt-20">
          <div className="container mx-auto">
            <div className="col-xl-8 col-lg-12 col-md-12 text-center">
              <div className="hero-content">
                <h1 className="text-3xl md:text-5xl lg:text-7xl font-bold">
                  <span className="uppercase text-orange-600">rto </span>
                  <br />
                  Vehicle Information
                </h1>
                <div>
                  <div className="my-5 relative flex flex-col md:flex-row justify-center gap-5 items-start">
                    <div className="relative">
                      <div className="shadow-sm">
                        <div className="w-[300px] bg-white sm:w-[500px] border-gray-200 border-2 px-16 py-2 rounded-md">
                          <input
                            className="w-full bg-transparent uppercase"
                            type="text"
                            value={search}
                            placeholder="Search Vehicle MH01AB1234"
                            onChange={handleSearch}
                          />
                        </div>
                        <div className="absolute top-[7px] left-2">
                          <AiOutlineSearch size={30} />
                        </div>
                      </div>
                      {error && (
                        <div className="text-red-500 text-sm bg-transparent">
                          Please Enter Currectly. Example
                          <span className="ml-1 text-black font-semibold">
                            MH01AB1234
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="rounded-md active:bg-gray-800 bg-gray-700 text-white">
                      <button className="px-5 py-2" onClick={fetchDetails}>
                        {loading ? (
                          <div>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline w-4 h-4 mr-3 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                            Loading...
                          </div>
                        ) : (
                          <div className="font-bold text-white">Find</div>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="mt-2">
                    <a
                      className="app-store-btn btn-google mt-3 ml-1 mr-1"
                      target="_blank"
                      href="/"
                      role="button"
                    >
                      <span className="app-store-btn-subtitle">Get It On</span>
                      <span className="app-store-btn-title">Google Play</span>
                    </a>
                    <a
                      className="app-store-btn btn-apple mt-3 ml-1 mr-1"
                      target="_blank"
                      href="/"
                      role="button"
                    >
                      <span className="app-store-btn-subtitle">
                        Available on the
                      </span>
                      <span className="app-store-btn-title">App Store</span>
                    </a>
                  </div>
                </div>
                {Object.keys(data).length > 0 && (
                  <div className="mt-5">
                    <div className="relative bg-white p-4 border border-gray-300 rounded-md shadow-md">
                      <div
                        onClick={() => setData([])}
                        className="absolute cursor-pointer right-5"
                      >
                        <AiOutlineCloseCircle size={30} />
                      </div>
                      <h2 className="text-2xl font-semibold mb-4">
                        Vehicle Details
                      </h2>
                      <div className="grid grid-cols-1 leading-7 justify-items-center md:grid-cols-2 md:grid-rows-2 lg:grid-cols-2 text-left gap-10">
                        <div className="px-2 min-w-[350px]">
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Registration No:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.registrationNo}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Owner Name:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.ownerName}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Owner Mobile No:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.ownerMobileNo}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Chassis No:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.chassisNo}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Engine No:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.engineNo}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <strong className="font-semibold">
                              Vehicle Color:
                            </strong>
                            <div className="max-w-[180px] text-right">
                              {data.vehicleColor}
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://www.google.com/",
                                "_blank",
                                "noreferrer"
                              )
                            }
                            className="bg-blue-500 text-white cursor-pointer font-bold px-5 py-2 rounded-lg mt-5 w-full"
                          >
                            Show More
                          </button>
                        </div>

                        <div className="px-2 min-w-[350px]  row-span-2">
                          <RenderPanel
                            title={"RC Status:"}
                            value={data.rcStatus}
                          />
                          <RenderPanel
                            title={"Insurance From:"}
                            value={data.insuranceFrom}
                          />
                          <RenderPanel
                            title={"Insurance Up To:"}
                            value={data.insuranceUpto}
                          />
                          <RenderPanel
                            title={"Insurance Policy No:"}
                            value={data.insurancePolicyNo}
                          />
                          <RenderPanel
                            title={"Issuer Name:"}
                            value={data.issuerName}
                          />
                          <RenderPanel
                            title={"Fitness Up To:"}
                            value={data.fitnessUpto}
                          />
                          <RenderPanel
                            title={"Fuel Type:"}
                            value={data.fuelType}
                          />
                          <RenderPanel
                            title={"Fuel Norms:"}
                            value={data.fuelNorms}
                          />
                          <RenderPanel
                            title={"PUC Up To:"}
                            value={data.pucUpto}
                          />
                          <RenderPanel
                            title={"Age:"}
                            value={data.age || "N/A"}
                          />
                          <RenderPanel
                            title={"Ownership:"}
                            value={data.ownershipDesc}
                          />
                          <RenderPanel
                            title={"Seat Capacity:"}
                            value={data.seatCapacity}
                          />
                          <RenderPanel
                            title={"Unload Weight:"}
                            value={data.unloadWeight}
                          />
                          <RenderPanel
                            title={"Last Seen On:"}
                            value={data.last_seen_on}
                          />
                        </div>

                        <div className="px-2 min-w-[350px]">
                          <RenderPanel
                            title={"Vehicle Model:"}
                            value={data.modelName}
                          />
                          <RenderPanel
                            title={"Maker Model:"}
                            value={data.makerModel}
                          />
                          <RenderPanel
                            title={"Manufacture Month/Year:"}
                            value={data.manufactureMonthYear}
                          />
                          <RenderPanel
                            title={"Registration Date:"}
                            value={data.registrationDate}
                          />
                          <RenderPanel
                            title={"Registration Authority:"}
                            value={data.registrationAuthority}
                          />
                          <RenderPanel
                            title={"Vehicle Class:"}
                            value={data.vehicleClass}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute -z-50 top-52 sm:top-20 md:top-32 lg:-top-20 xl:-top-52 2xl:-top-80">
          <MainHeader />
        </div>
        <div className="">
          <About />
        </div>
        <div className="mt-5">
          <Services />
        </div>
      </div>
    </>
  );
}

export default Home;
