import axios from "axios";

const API_CONSTANT = {
  BASE_URL: "https://rto.saranginfotech.in/api/v1/",
  VEHICLE: "search-vehicle-details",
  LICENSE: "search-license-details",
};

const api = axios.create({
  baseURL: API_CONSTANT.BASE_URL,
  timeout: 3000,
});

const get = (endpoint) => {
  return new Promise((resolve, reject) => {
    api
      .get(endpoint)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const post = (endPoint, params) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${API_CONSTANT.BASE_URL}${endPoint}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { get, post, API_CONSTANT };
