import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Header";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import EChallan from "./components/Pages/EChallan";
import LicenseInfo from "./components/Pages/LicenseInfo";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <div>
          <Navbar />
        </div>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/license-info" element={<LicenseInfo />} />
            <Route path="/e-challan" element={<EChallan />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
