import React from "react";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { GrClose } from "react-icons/gr";

function Navbar() {
  const [open, setOpen] = useState(false);
  const location = useLocation(); // Get the current location object
  const pathname = location.pathname; // Get the pathname from the location object

  const navdata = [
    { title: "Vehicle Info", pathname: "/" },
    { title: "License Info", pathname: "/license-info" },
    { title: "E-Challan", pathname: "/e-challan" },
  ];

  return (
    <>
      <div className="bg-slate-200 relative p-2 flex  items-center md:grid md:grid-cols-2 z-10">
        <div className="flex lg:hidden justify-start items-center">
          <FaBars
            onClick={() => {
              setOpen(!open);
            }}
            className="cursor-pointer"
          />
        </div>

        <div className="flex justify-end md:justify-start items-center">
          <Link to="/">
            <div className="px-2 py-[2px] flex justify-center items-center gap-2">
              <div className="rounded-3xl w-fit overflow-hidden">
                <img className="w-16  " src="/logo.png" alt="logo" />
              </div>
            </div>
          </Link>
        </div>

        {/* for laptop */}
        <div className="hidden lg:flex justify-end items-center gap-10 mr-10">
          {navdata.map((item, index) => {
            return (
              <Link
                key={index}
                to={item?.pathname}
                className={`${
                  pathname === item?.pathname
                    ? "font-bold text-xl text-gray-800 text-center"
                    : ""
                } border-transparent text-center hover:border-gray-500 transition-all `}
              >
                {item?.title}
              </Link>
            );
          })}
        </div>
      </div>
      <div
        className={`transition-transform duration-500 transform ${
          open ? "translate-x-0" : "-translate-x-full"
        } flex-col min-h-full w-[280px] absolute z-50 top-0 bg-white`}
      >
        <div className="mt-5 flex justify-end px-5">
          <GrClose
            onClick={() => {
              setOpen(!open);
            }}
            className="cursor-pointer"
          />
        </div>

        <div className="flex justify-center flex-col items-center">
          <Link to="/">
            <div className="flex justify-center flex-col items-center gap-2">
              <div className="rounded-2xl w-fit overflow-hidden">
                <img className="w-12" src="/logo.png" alt="logo" />
              </div>
              <p className="text-[18px] text-[#2B2B2B] font-medium">
                RTO Vehicle Information
              </p>
            </div>
          </Link>
        </div>

        {/* for mobile */}
        <div className="mt-10 grid grid-cols-1 gap-4 px-7 container mx-auto">
          {navdata.map((item, index) => {
            return (
              <Link
                key={index}
                onClick={() => {
                  setOpen(!open);
                }}
                to={item?.pathname}
                className={`${
                  pathname === item?.pathname
                    ? "font-bold text-xl h-10 text-gray-800 flex items-center justify-center hover:border-gray-500 transition-all"
                    : "h-10 text-base flex items-center justify-center"
                } border-transparent text-center`}
              >
                {item?.title}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Navbar;
