import React from 'react'

function Services() {
    return (
        <div className='mx-5'>
            <div className='container mx-auto text-center max-w-2xl mt-10'>
                <h1 className='text-[#2B2B2B] font-serif text-5xl font-bold'>Best Features</h1>
                <p className='text-[#999FAE] font-medium text-[18px] mt-5'>Our real-ti  me Challan Monitoring feature ensures you're always up-to-date on any traffic violations associated with your vehicle. Receive instant notifications the moment a new challan is issued, so you can take prompt action and avoid late fees.</p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 my-10 container mx-auto max-w-[1300px]'>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/check_challan.svg" alt="Check Challan Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Check Challan</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Check and manage your vehicle's challans effortlessly. Stay updated with real-time notifications and fines.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/licence_info.svg" alt="License Info Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>License Info</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Access and manage your driver's license information with ease. Stay compliant with driving regulations.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/dally_fuel_price.svg" alt="Daily Fuel Price Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Daily Fuel Price</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Get real-time updates on fuel prices, helping you save on your daily commute.</p>
                    </div>
                </div>
                {/* <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/rto_exam.svg" alt="RTO Exam Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>RTO Exam</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Prepare for your RTO exam with comprehensive study materials and practice tests.</p>
                    </div>
                </div> */}
                {/* <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/near_by.svg" alt="Nearby Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Nearby</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Find nearby gas stations, restaurants, and other essential places while on the road.</p>
                    </div>
                </div> */}
                {/* <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/driving_school.svg" alt="Driving School Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Driving School</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Discover and enroll in accredited driving schools to enhance your skills.</p>
                    </div>
                </div> */}
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/car_details.svg" alt="Car Details Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Car Details</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Access detailed information about your vehicle, including specs and maintenance records.</p>
                    </div>
                </div>
                {/* <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/resale_value.svg" alt="Resale Value Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Resale Value</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Estimate the resale value of your vehicle and make informed selling decisions.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/expense_manager.svg" alt="Expense Manager Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Expense Manager</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Track and manage your vehicle-related expenses with ease and accuracy.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/speedometer.svg" alt="Speedometer Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Speedometer</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Monitor your vehicle's speed in real-time with a built-in digital speedometer.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/mileage_loan_calc.svg" alt="Mileage & Loan Calculator Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Mileage & Loan Calculator</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Calculate your vehicle's mileage and loan payments for better financial planning.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-5 hover:bg-white hover:shadow-md p-7'>
                    <div><img src="/svg/vehicle_workshop_dealers.svg" alt="Vehicle Workshop Dealers Icon" /></div>
                    <div className=''>
                        <p className='font-bold capitalize text-[21px] text-[#2B2B2B]'>Vehicle Workshop Dealers</p>
                    </div>
                    <div>
                        <p className='text-[##999FAE] text-[17px] font-medium'>Find reputable vehicle workshops and dealerships for maintenance and repairs.</p>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default Services